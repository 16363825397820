@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Padyakke+Expanded+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&family=Cutive+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");

@import "tailwindcss/lib/css/preflight.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
